/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.grid .grid-item a.highlighted').matchHeight({ byRow: false });
        $('.grid .grid-item a.highlighted h3').matchHeight({ byRow: false });
        $('.grid .grid-item a.highlighted span.artist-list').matchHeight({ byRow: false });
        $('.grid .grid-item a.highlighted span.details').matchHeight({ byRow: false });

//         $('.grid .grid-item a.highlighted p.venue').matchHeight();

        $('.slides').slick({
          arrows: false,
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 4000,
          speed: 300,
          pauseOnHover: false,
          fade: true,
          cssEase: 'linear',
          rows: 0
        });

        $('.thanks-gallery').slick({
          arrows: false,
          infinite: true,
          dots: false,
          autoplay: true,
          autoplaySpeed: 4000,
          speed: 800,
          pauseOnHover: false,
          fade: true,
          cssEase: 'linear',
          rows: 0
        });

				$('.callouts .callout article').matchHeight();
				$('.callouts .callout img').matchHeight();
// 				$('.callouts .callout').matchHeight();

        $('.taxonomy-display li .btn').on('click', function (e) {
          var displayTile = $(this).attr('id');
          $('.taxonomy-display li .btn').removeClass('on');
          $(this).addClass('on');
          $('.grid-item').addClass('hidden');
          $('.grid-item.'+displayTile).removeClass('hidden');
          return false;
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // faq
    'faq': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#menu-mobile-navigation .nav-item-25').addClass('active');

      }
    },
    // contact
    'contact': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#menu-mobile-navigation .nav-item-25').addClass('active');

      }
    },
    // resolutions
    'resolutions': {
      init: function() {
        // JavaScript to be fired on the home page
//         $('#menu-mobile-navigation .nav-item-25').addClass('active');

      }
    },
    // faq
    'faq': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#menu-mobile-navigation .nav-item-25').addClass('active');

      }
    },
    // event
    'single_event': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#menu-mobile-navigation .nav-item-27').addClass('active');

      }
    },
    // post
    'single_post': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#menu-mobile-navigation .nav-item-289').addClass('active');

      }
    },
    // archive
    'archive': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#menu-mobile-navigation .nav-item-289').addClass('active');

      }
    },
    // venue
    'single_venue': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#menu-mobile-navigation .nav-item-27').addClass('active');

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
